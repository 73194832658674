<template>
    <MultiLanguageContent>
        <template #th>
            <CookieThBanner />
        </template>
        <template #en>
            <CookieEnBanner />
        </template>
    </MultiLanguageContent>
</template>
