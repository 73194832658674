<template>
    <div class="center">
        Create for fun by jtm [jetaime.dev]
    </div>
</template>
<style>
.center {
    margin: 50px auto;
    text-align: center;
}
</style>
