<template>
    <div>
        <NuxtLink to="/animation">
            <AppNavLink>
                <div class="speech-bubble">Animation</div>
            </AppNavLink>
        </NuxtLink>
        <NuxtLink to="/control">
            <AppNavLink>
                <div class="speech-bubble">Control</div>
            </AppNavLink>
        </NuxtLink>
        <NuxtLink to="/dance">
            <AppNavLink>
                <div class="speech-bubble">Dance</div>
            </AppNavLink>
        </NuxtLink>
        <NuxtLink to="/about">
            <AppNavLink>
                <div class="speech-bubble">About</div>
            </AppNavLink>
        </NuxtLink>
        <NuxtLink to="/settings">
            <AppNavLink>
                <div class="speech-bubble">Settings</div>
            </AppNavLink>
        </NuxtLink>
        <!--
            <NuxtLink to="https://github.com/worraanong/moodeng" target="_blank">
                <AppNavLink>
                    <div class="speech-bubble">View on GitHub</div>
                </AppNavLink>
            </NuxtLink>
        -->
    </div>
</template>