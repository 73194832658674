<script setup lang="ts">

import { CookieConsentBanner } from '../../utils/cookie-consent-banner'

const banner = new CookieConsentBanner({
    lang: 'th',
    consentText: 'เว็บไซต์ของเราจัดเก็บไฟล์ข้อมูลขนาดเล็ก หรือที่เรียกว่าคุกกี้ เพื่อพัฒนาประสบการณ์ที่ดีในการใช้เว็บไซต์ สามารถอ่านรายละเอียดเพิ่มเติมได้ที่ ',
    policyLinkText: 'นโยบายการใช้งานคุ้กกี้',
    necessaryCookieText: 'คุ้กกี้ที่จำเป็นเ เพื่อจัดเก็บการตั้งค่าเว็บไซต์ และเพิ่มความปลอดภัย',
    nonNecessaryCookieText: 'คุ้กกี้บุคคลที่สาม เพื่อเก็บรวบรวมข้อมูลและวิเคราะห์ผลการใช้งาน',
    acceptText: 'ยอมรับ'
})

</script>
<template>
    <div>
        <CookieBanner :banner="banner" />
    </div>
</template>