<script setup lang="ts">
import type { Cookie } from '~/utils/cookie';
const cookie = useCookie<Cookie>('_moodeng')
</script>

<template>
    <div class="header">
        <div v-if="$device.isDesktop">
            <AppNavWrapper>
                <AppNavBar />
            </AppNavWrapper>
        </div>
        <div v-else>
            <AppNavMobile />
        </div>
    </div>
    <div v-if="!cookie?.consent.collected">
        <CookieConsentBanner />
    </div>
</template>

<style>
.header {
    margin-bottom: 30px;
}
</style>