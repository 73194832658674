<script lang="ts" setup>
const props = defineProps({
    checked: { type: Boolean, default: true },
})
const switchChecked = ref(props.checked)
const toggle = () => {
    if (switchChecked.value) {
        emit('checked');
    }
    else emit('unchecked')
}
const emit = defineEmits(['checked', 'unchecked']);

</script>

<template>
    <div>
        <label class="switch">
            <input v-model="switchChecked" type="checkbox" @change="toggle()">
            <span class="slider round"></span>
        </label>
    </div>
</template>

<style>
.switch {
    position: relative;
    display: inline-block;
    width: 72px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(38px);
    -ms-transform: translateX(38px);
    transform: translateX(38px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>