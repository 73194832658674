<script setup lang='ts'>
const lang = useState('language')
</script>
<template>
    <div>
        <div v-if="lang == 'th'">
            <slot name="th"/>
        </div>
        <div v-else>
            <slot name="en"/>
        </div>
    </div>
</template>
