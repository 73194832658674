<script setup lang="ts">

import { CookieConsentBanner } from '../../utils/cookie-consent-banner'

const banner = new CookieConsentBanner({
    consentText: 'Our website stores small amount data for remembering your preferences, as known as cookies. The purpose is to improve the user experience of the website. Read more details at our ',
    necessaryCookieText: 'Required cookies for store web settings, and improve security',
    nonNecessaryCookieText: 'Third-party cookies for collect data and analyze usage results'
})

</script>
<template>
    <div>
        <CookieBanner :banner="banner" />
    </div>
</template>