export class CookieConsentBanner {
  readonly lang?: string = "en";
  readonly consentText?: string = "Our website uses cookie";
  readonly policyLink?: string = "/cookie-policy";
  readonly policyLinkText?: string = "Cookie Policy";
  readonly necessaryCookieText?: string = "Store user preferences";
  readonly nonNecessaryCookieText?: string = ""; // []
  readonly acceptText?: string = "Accept";

  constructor(params: CookieConsentBanner) {
    Object.assign(this, params);
  }
}
