<script setup lang="ts">
import type { Cookie } from '~/utils/cookie';
import { CookieConsentBanner } from '../../utils/cookie-consent-banner'
const props = defineProps({
    banner: { type: CookieConsentBanner, default: new CookieConsentBanner({}) },
})
const cookie = useCookie<Cookie>('_moodeng')
const defaultAnalysisCookieAccepted = (cookie.value) ? cookie.value.consent?.analysis : true // <- only first time appear
// after language change we created cookie and default became 'false' 
const analysisCookieAccepted = ref(defaultAnalysisCookieAccepted)

const accept = () => {
    cookie.value.consent.collected = true
    cookie.value.language = useState('language') as unknown as string
    cookie.value.consent.analysis = analysisCookieAccepted.value
    if (analysisCookieAccepted.value) {
        consentGrantedAnalysis()
    }
    emit('accepted');
}

const consentGrantedAnalysis = () => {
    const { gtag } = useGtag()
    gtag('consent', 'update', {
        analytics_storage: 'granted'
    })
}

const updateConsentCookie = (value: boolean) => {
    analysisCookieAccepted.value = value
}
const emit = defineEmits(['accepted']);
</script>

<template>
    <div class="cookie-consent-banner">
        <div class="inner">
            <div class="misc">
                <!-- <SelectLanguage /> -->
            </div>
            <div class="desc">
                {{ props.banner.consentText }}
                <NuxtLink :to="props.banner.policyLink">{{ props.banner.policyLinkText }}</NuxtLink>
            </div>
            <ul>
                <li>
                    {{ props.banner.necessaryCookieText }}
                    <div :class="$device.isDesktop ? 'float-right' : ''">
                        {{ props.banner.lang == 'th' ? '(เปิดใช้งานตลอด)' : '(Always enabled)' }}
                    </div>
                </li>
                <li v-if="props.banner.nonNecessaryCookieText">
                    {{ props.banner.nonNecessaryCookieText }}
                    <div class="float-right">
                        <ToggleSwitch :checked="analysisCookieAccepted" @checked="updateConsentCookie(true)"
                            @unchecked="updateConsentCookie(false)" />
                    </div>
                </li>
            </ul>

            <div class="btn-accept">
                <button @click="accept()">{{ props.banner.acceptText }}</button>
            </div>
        </div>
    </div>
</template>

<style>
.cookie-consent-banner {
    position: fixed;
    bottom: 10px;
    left: 0;
    z-index: 3;
    width: 100%;

    background-color: #F1F6F4;
}

.cookie-consent-banner>.inner {
    max-width: 960px;
    margin: 10px auto;
    padding: 32px 12px;
}

.cookie-consent-banner>.inner>.misc {
    text-align: right;
    margin-bottom: 1rem;
}

.cookie-consent-banner>.inner>.desc {
    margin-bottom: 1.5rem;
}

.cookie-consent-banner>.inner>.btn-accept {
    text-align: center;
}

.cookie-consent-banner>.inner>.btn-accept>button {
    min-width: 200px;
}

.cookie-consent-banner>.inner>ul>li {
    margin-bottom: 1rem;
}
</style>