const defaultLanguage = "en";
const getBrowserLanguages = () => {
  return navigator.languages === undefined
    ? [navigator.language]
    : navigator.languages;
};
export default function getPreferLanguage() {
    
  const preferredLanguages = getBrowserLanguages();
  if (!preferredLanguages) {
    return defaultLanguage;
  }
  if (
    _Includes(preferredLanguages, "th") ||
    _Includes(preferredLanguages, "th-TH") 
    //TODO: Note that in Safari on iOS prior to 10.2, the country code returned is lowercase: "en-us", "fr-fr" etc.
  ) {
    return "th";
  }
  return defaultLanguage;
}

/* About language logic 
GET
Cookie > getPreferLanguage() > defaultLanguage
SET


*/