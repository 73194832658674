import { CookieConsent } from "./cookie-consent";

export class Cookie {
  consent: CookieConsent;
  language?: string;

  constructor() {
    this.consent = new CookieConsent()
  }
}
