import type { Cookie } from "~/utils/cookie";
const consentGrantedAnalysis = () => {
  const { gtag } = useGtag();
  gtag("consent", "update", {
    analytics_storage: "granted",
  });
};
export default function updateGtagConsent(cookie:Cookie) {
  if (cookie.consent.collected && cookie.consent.analysis) {
    consentGrantedAnalysis();
  }
}