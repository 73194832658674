<script setup lang="ts">
import '~/assets/css/main.css'
import { Cookie } from '~/utils/cookie';
import getPreferLanguage from './utils/preferred-language';
import updateGtagConsent from './utils/cookie-third-party-settings';
useSeoMeta({
  title: 'Moo Deng',
  ogTitle: 'Moo Deng',
  description: 'หมูเด้ง จะแจกความฉดใฉหั้ยทุกค้นนน ♥',
  ogDescription: 'หมูเด้ง จะแจกความฉดใฉหั้ยทุกค้นนน ♥',
  ogImage: 'https://moodeng.nuxt.dev/og.png',
  ogImageType: 'image/png',
  ogImageAlt: 'Moo Deng',
  ogImageHeight: 628,
  ogImageWidth: 1200,
  twitterCard: 'summary_large_image',
})
useHead({
  htmlAttrs: {
    lang: "en",
  },
  meta: [
    { name: 'google-site-verification', content: 'ZDXPyaAX_avZDVoYMGHWXztDWeQZDOzO-HtfT28MTXw' }
  ]
});

onBeforeMount(() => {
  const cookie = useCookie<Cookie>('_moodeng')
  cookie.value = (cookie.value || new Cookie())
  useState('language', () => cookie.value.language || getPreferLanguage())
  updateGtagConsent(cookie.value)
})

</script>
<template>
  <div>
    <AppHeader />
    <NuxtRouteAnnouncer />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <AppFooter />
  </div>
</template>
