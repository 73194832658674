import { default as _91_46_46_46slug_93HxQxjzxhjNMeta } from "/opt/buildhome/repo/pages/[...slug].vue?macro=true";
import { default as aboutcGvMViY0SnMeta } from "/opt/buildhome/repo/pages/about.vue?macro=true";
import { default as animationRnJ1IkxOBoMeta } from "/opt/buildhome/repo/pages/animation.vue?macro=true";
import { default as controlioPVcQFFLjMeta } from "/opt/buildhome/repo/pages/control.vue?macro=true";
import { default as cookie_45policyR0Q3gHg5HMMeta } from "/opt/buildhome/repo/pages/cookie-policy.vue?macro=true";
import { default as dancebAcMNBlzZvMeta } from "/opt/buildhome/repo/pages/dance.vue?macro=true";
import { default as drawerEGj3VqoiuJMeta } from "/opt/buildhome/repo/pages/drawer.vue?macro=true";
import { default as entrywMP4WfziquMeta } from "/opt/buildhome/repo/pages/entry.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as mini_45game0vzqW0kxrmMeta } from "/opt/buildhome/repo/pages/mini-game.vue?macro=true";
import { default as settings4cDYwTsA31Meta } from "/opt/buildhome/repo/pages/settings.vue?macro=true";
import { default as termsjw1825O2ZdMeta } from "/opt/buildhome/repo/pages/terms.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/opt/buildhome/repo/pages/[...slug].vue")
  },
  {
    name: "about",
    path: "/about",
    component: () => import("/opt/buildhome/repo/pages/about.vue")
  },
  {
    name: "animation",
    path: "/animation",
    component: () => import("/opt/buildhome/repo/pages/animation.vue")
  },
  {
    name: "control",
    path: "/control",
    component: () => import("/opt/buildhome/repo/pages/control.vue")
  },
  {
    name: "cookie-policy",
    path: "/cookie-policy",
    component: () => import("/opt/buildhome/repo/pages/cookie-policy.vue")
  },
  {
    name: "dance",
    path: "/dance",
    component: () => import("/opt/buildhome/repo/pages/dance.vue")
  },
  {
    name: "drawer",
    path: "/drawer",
    component: () => import("/opt/buildhome/repo/pages/drawer.vue")
  },
  {
    name: "entry",
    path: "/entry",
    component: () => import("/opt/buildhome/repo/pages/entry.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "mini-game",
    path: "/mini-game",
    component: () => import("/opt/buildhome/repo/pages/mini-game.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/opt/buildhome/repo/pages/settings.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/opt/buildhome/repo/pages/terms.vue")
  }
]